.input-form{
    display:flex; 
    align-items: center; 
    justify-content: center;   

}


.form-footer{
    padding: 10px;

}
