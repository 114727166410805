.player-card{
   text-align: left;
   margin: 20px;
   border-radius: 20px;
   box-shadow:  10px 10px 0.8em #9db0c8;
}

.palyer-card-content{
   padding: 4px;
  
}
.adm-card-body {
   padding: 3px 0;
}
.adm-card {
   background:  linear-gradient(100deg, rgba(222, 228, 237,1) 50%, rgba(239, 242, 246,1) 70%, rgba(255,2255,255,1) 100%);
}
.adm-list-default .adm-list-body {
   border-top: none;
}
.adm-list-item-content-main {
   padding: 6px;
}
.adm-list-item  {
   background:  linear-gradient(100deg, rgba(222, 228, 237,1) 50%, rgba(239, 242, 246,1) 70%, rgba(255,2255,255,1) 100%);
}

.ant-modal-content{
   max-height: 500px;
   overflow: scroll;
}